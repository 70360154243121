<template>
  <div v-if="configurations.length>0">
    <user-holidays></user-holidays>
  </div>
</template>

<script>
import Holidays from "@/components/admin/Holidays";
import { mapState} from "vuex";

export default {
  name: "Holidays",
  components: {
    'user-holidays': Holidays
  },
  async beforeCreate() {
    await this.$store.dispatch("configuration/retriveConfigurations");
  },

  data(){
    return {
    };
  },
  computed: {
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    })
  }
}
</script>

<style scoped>

</style>