<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{ $t("Manage Holidays") }}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{filteredHolidays.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search.searchWord"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                   <template v-slot:prepend-inner>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                        </template>
                        <span>{{$t("advanced")}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="addItem" v-if="$checkPermission('AdminMenu.Read')">{{$t("newholiday")}}</v-btn>
            </v-col>
            
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t(formTitle)}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-form  v-on:submit.prevent ref="form"
              v-model="valid"
              lazy-validation >
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpStringE" :rules="[rules.required]" outlined dense :label="$t('Holiday string in English')" :placeholder="$t('Holiday string in English')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpStringF" :rules="[rules.required]" outlined dense :label="$t('Holiday string in French')" :placeholder="$t('Holiday string in French')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                      <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto">
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              prepend-inner-icon="mdi-calendar"
                              outlined dense
                              :rules="[rules.required]" v-model="requestConfiguration.LookUpKey"
                              v-on="on" :label="$t('Holiday date')" :placeholder="$t('Holiday date')"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            dark
                            :rules="[rules.required]" v-model="requestConfiguration.LookUpKey"
                          ></v-date-picker>
                      </v-menu>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close = dialog = false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="save">{{$t('save')}}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_adv"  max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="headline">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                      v-model="filters.year"
                      :items="yearRange"
                      :label="$t('selectyear')"
                      :placeholder="$t('selectyear')"
                      outlined dense
                      hide-details
                      clearable
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close = dialog_adv = false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="filterHolidays">{{$t('search')}}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>{{$t('delete')}}</v-card-title>
          <v-card-text>{{$t('Are you sure you want to delete this configuration?')}}</v-card-text>
          <v-card-actions>
            <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{$t('Close')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="deleteConfig()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
       <v-divider  v-show="selectedYear"></v-divider>       
         <v-row class="pa-0 ma-0 white" v-show="selectedYear">
            <v-col class="pa-0 ma-0" cols="12"> 
              <v-scroll-x-transition hide-on-leave>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip  v-on='on' v-bind="attrs"
                    class="ma-1"
                    color="custom-color-accent"
                    close
                    small
                    close-icon="mdi-delete"
                    @click:close="clearFilters()">
                      <span class="text-capitalize">{{filters.year}}</span>
                    </v-chip>
                  </template>
                  <span>{{$t("removefilter")}}</span>
                </v-tooltip>
                </v-scroll-x-transition>
            </v-col>
        </v-row>
         
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081" 
          v-if="configurations"
          :headers="headers"
          :items="filteredHolidays"
          sort-by="id"
          class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
          :search="search.searchWord"
          :loading="loading" 
          :loading-text="$t('Loading')"
          :footer-props="{ 'items-per-page-text': $t('rowsperpage') }"
        >
        <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
        <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
               <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";
import range from "lodash/range";
export default {
  name: "Holidays",
  data(){
    return {
      valid: true,
      rules: {
          required: value => !!value || this.$t('required'),
      },
      yearRange: range(moment().year(), moment().year()+30, 1),
      year:moment().year(),
      selectedYear:null,
      LookUpName:'',
      editFlag: false,
      deletedItem:"",
      assign: false,
      dialog_adv: false,
      selected: null,
      selectedItem: null,
      disabled: true,
      editedIndex: 0,
      headers: [
        {
          text:  this.$t("ID"),
          align: "left",
          sortable: true,
          value: "LookUpTableId"
        },
        { text: this.$t("nameinenglish"), value: "LookUpStringE"},
        { text: this.$t("nameinfrench"), value: "LookUpStringF"},
        { text: this.$t("date"), value: "LookUpKey" },
        { text: "", value: "action", sortable: false }
      ],
      dialog: false,
      error: "",
      dialogDelete: false,
      lookupnames: [],
      filteredHolidays: [],
      search: {
        searchword:'',
      },
      filters: {
        year:moment().year(),
      },
    }
  },
  async mounted(){
    await this.filterHolidays();
  },
  computed: {
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration',
      formTitle() {
        return this.editedIndex === 0 ? "newholiday" : "editholiday";
      }
    })
  },
  watch: {
  },
  methods:{
    ...mapActions('configuration',['addConfiguration', 'updateConfiguration','setConfigurationItem','setFlagEdit', 'removeConfiguration', 'emptyConfiguration']),
    addItem(){
      this.dialog = true;
      this.editedIndex = 0;
      this.editFlag = false;
      this.emptyConfiguration();
    },
    formatDate(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    clearFilters(){
      this.filters.year=null;
      this.filterHolidays();
    },
    filterHolidays(){
      if(this.filters.year!=null)
        this.filteredHolidays=this.configurations.filter( filter => filter.LookUpName === 'Holiday' ).filter(item=>moment(item.LookUpKey,'YYYY-MM-DD').year()==this.filters.year)
      else
        this.filteredHolidays=this.configurations.filter( filter => filter.LookUpName === 'Holiday' )
      
      this.selectedYear=this.filters.year;
      this.dialog_adv=false;
    },
    save(){
      this.requestConfiguration.LookUpName = 'Holiday';
      if(this.editFlag){
         if(this.$refs.form.validate()){
          this.updateConfiguration(this.requestConfiguration).then(()=>{
            this.filterHolidays(); 
            let color="dark";
            let alert="itemUpdatedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
            this.editFlag = false;
          })
        }
        else
          return
      }else{
        if(this.$refs.form.validate()){
          this.addConfiguration(this.requestConfiguration).then(()=>{
            this.filterHolidays();
            let color="success";
            let alert="itemAddedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
          })
          
        }
        else
          return
      }
      this.dialog = false;
    },
    editItem(item){
        this.dialog = true;
        this.editedIndex = -1;
        this.editFlag = true;
        this.setConfigurationItem(item);
    },
    deleteItem(item){
        this.deletedItem = item;
        this.dialogDelete = true;
    },
    async deleteConfig(){
      this.removeConfiguration( this.deletedItem.LookUpTableId ).then(()=>{
          this.dialogDelete = false;
          this.filterHolidays(); 
          let color="dark";
          let alert="itemRemovedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
      })
    },
    close() {
      this.assign = false;
      this.selected = null;
      this.loading = false;
    },
     ...mapActions('configuration',['retriveConfigurations']),
  }

}
</script>

<style scoped>

</style>